<template>
  <div class="_bg-default _80w _100vh">
    <div class="_100top pa-3">
      <v-card class="radius-card pa-3">
        <h3>Request Transaksi</h3>
        <v-row>
          <v-col cols="3">
            <v-card class="pa-2 rounded-lg" outlined>
              <p class="small_txt color_txt ma-0">
                Klik nama psikolog atau client untuk melihat profile
              </p>
            </v-card>
          </v-col>
          <v-col cols="7"></v-col>
          <v-col cols="2">
            <p class="mb-0">Sortir</p>
            <div class="d-flex align-center">
              <v-select
                :items="listSort"
                v-model="sort"
                item-text="nama"
                item-value="val"
                dense
                hide-details
                outlined
                @change="fetchItems"
              ></v-select>
            </div>
          </v-col>
        </v-row>
        <div>
          <div class="mt-3" v-if="list">
            <v-data-table
              :headers="headers"
              :items="list.data"
              hide-default-footer
              :page.sync="page"
              :items-per-page="limit"
              class="elevation-0 my-5"
            >
              <template v-slot:[`item.psycholog`]="{ item }">
                <div class="d-flex">
                  <v-btn depressed class="mr-2" @click.prevent="viewPsy(item)">
                    <p class="ma-0 small_txt">
                      {{ item.psycholog ? item.psycholog.nama_lengkap : 'Deleted User' }}
                    </p>
                  </v-btn>
                </div>
              </template>
              <template v-slot:[`item.client`]="{ item }">
                <div class="d-flex">
                  <v-btn
                    depressed
                    class="mr-2"
                    @click.prevent="viewClient(item)"
                  >
                    <p class="ma-0 small_txt">{{ item.public ? item.public.nama_lengkap : 'Deleted User' }}</p>
                  </v-btn>
                </div>
              </template>
              <template v-slot:[`item.sesi`]="{ item }">
                <div class="d-flex" v-if="item.sessions[0]">
                  <p>{{ item.sessions[0].schedule.booking_time }}</p>
                </div>
              </template>
              <template v-slot:[`item.status`]="{ item }">
                <v-chip
                  dark
                  :color="
                    item.status == 0
                      ? 'yellow'
                      : item.status == 1
                      ? 'green'
                      : 'red'
                  "
                >
                  {{
                    item.status == 0
                      ? "Pending"
                      : item.status == 1
                      ? "Aproved"
                      : item.status == 2
                      ? "Rejected"
                      : "EXPIRED"
                  }}
                </v-chip>
              </template>
              <template v-slot:[`item.request`]="{ item }">
                <div class="d-flex">
                  <v-btn depressed class="mr-2" @click.prevent="viewReq(item)">
                    View Evidence
                  </v-btn>
                </div>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <div class="d-flex">
                  <v-btn
                    dark
                    depressed
                    color="cyan"
                    class="mr-2"
                    style="min-width: 10px"
                    @click.prevent="updateItem(item)"
                  >
                    <v-icon dark small> mdi-cog </v-icon>
                  </v-btn>
                </div>
              </template>
            </v-data-table>

            <div class="text-center pa-3">
              <v-pagination
                v-model="page"
                circle
                @input="fetchItems"
                :length="list.last_page"
              ></v-pagination>
            </div>
            <v-row>
              <v-col cols="2">
                <p class="mb-0">Tampilkan</p>
                <div class="d-flex align-center">
                  <v-select
                    :items="listLimit"
                    v-model="limit"
                    dense
                    hide-details
                    outlined
                    @change="fetchItems"
                  ></v-select>
                  <p class="ml-1 mb-0">Data</p>
                </div>
              </v-col>
            </v-row>
            <div class="pa-2">
              <change-status-counseling
                :dialogAlert="dialogAlert"
                :id="dataUser"
                @refetch="fetchItems"
                @close="dialogAlert = false"
              />
              <profile-psy
                :selected="dataUser"
                :d_profilePsy="d_profilePsy"
                @close="d_profilePsy = false"
              />
              <profile-client
                :selected="dataUser"
                :d_profileClient="d_profileClient"
                @close="d_profileClient = false"
              />
              <view-evidence
                :selected="dataUser"
                :d_evidence="d_evidence"
                @close="d_evidence = false"
              />
            </div>
          </div>
        </div>
      </v-card>
    </div>
  </div>
</template>

<script>
import changeStatusCounseling from "../../components/Admin/Modal/changeStatusTrx.vue";
import ProfileClient from "../BATCH3/Counseling/Admin/profileClient.vue";
import ProfilePsy from "../BATCH3/Counseling/Admin/profilePsy.vue";
import ViewEvidence from "../BATCH3/Counseling/Admin/viewEvidence.vue";
export default {
  components: {
    changeStatusCounseling,
    ProfilePsy,
    ProfileClient,
    ViewEvidence
  },
  name: "requestTrx",
  data() {
    return {
      listSort: [
        { nama: "Terlama", val: "" },
        { nama: "Terbaru", val: "-created_at" }
      ],
      sort: "-created_at",
      list: null,
      headers: [
        {
          text: "ID",
          align: "start",
          value: "id"
        },
        {
          text: "PSYCHOLOGIST",
          align: "start",
          value: "psycholog"
        },
        {
          text: "CLIENT",
          align: "start",
          value: "client"
        },
        {
          text: "WAKTU KONSELING",
          align: "start",
          value: "sesi"
        },
        {
          text: "STATUS",
          align: "start",
          value: "status"
        },
        {
          text: "EVIDENCE",
          align: "start",
          value: "request"
        },
        {
          text: "ACTIONS",
          align: "start",
          value: "actions"
        }
      ],
      page: 1,
      limit: 10,
      listLimit: [5, 10, 20, 50, 70, 100],
      dialogAlert: false,
      tab: "tab-1",
      dataUser: null,
      d_profilePsy: false,
      d_profileClient: false,
      d_evidence: false
    };
  },
  mounted() {
    this.fetchItems();
  },
  methods: {
    fetchItems() {
      let data = {
        page: this.page,
        limit: this.limit,
        sort: this.sort
      };
      this.$store.dispatch("counseling/adminTrx", data).then(data => {
        data.data.forEach(el => {
          console.log(el.transactions_expired);
          if (el.transactions_expired == 1 && el.status == 0) {
            el["status"] = 3;
          }
        });
        this.list = data;
      });
    },

    updateItem(item) {
      this.dataUser = item.id;
      this.dialogAlert = true;
    },
    viewPsy(item) {
      this.dataUser = item.psycholog;
      this.d_profilePsy = true;
    },
    viewClient(item) {
      this.dataUser = item.public;
      this.d_profileClient = true;
    },
    viewReq(item) {
      this.dataUser = item.proof;
      this.d_evidence = true;
    }
  }
};
</script>

<style></style>
