var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"_bg-default _80w _100vh"},[_c('div',{staticClass:"_100top pa-3"},[_c('v-card',{staticClass:"radius-card pa-3"},[_c('h3',[_vm._v("Request Transaksi")]),_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('v-card',{staticClass:"pa-2 rounded-lg",attrs:{"outlined":""}},[_c('p',{staticClass:"small_txt color_txt ma-0"},[_vm._v(" Klik nama psikolog atau client untuk melihat profile ")])])],1),_c('v-col',{attrs:{"cols":"7"}}),_c('v-col',{attrs:{"cols":"2"}},[_c('p',{staticClass:"mb-0"},[_vm._v("Sortir")]),_c('div',{staticClass:"d-flex align-center"},[_c('v-select',{attrs:{"items":_vm.listSort,"item-text":"nama","item-value":"val","dense":"","hide-details":"","outlined":""},on:{"change":_vm.fetchItems},model:{value:(_vm.sort),callback:function ($$v) {_vm.sort=$$v},expression:"sort"}})],1)])],1),_c('div',[(_vm.list)?_c('div',{staticClass:"mt-3"},[_c('v-data-table',{staticClass:"elevation-0 my-5",attrs:{"headers":_vm.headers,"items":_vm.list.data,"hide-default-footer":"","page":_vm.page,"items-per-page":_vm.limit},on:{"update:page":function($event){_vm.page=$event}},scopedSlots:_vm._u([{key:"item.psycholog",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('v-btn',{staticClass:"mr-2",attrs:{"depressed":""},on:{"click":function($event){$event.preventDefault();return _vm.viewPsy(item)}}},[_c('p',{staticClass:"ma-0 small_txt"},[_vm._v(" "+_vm._s(item.psycholog ? item.psycholog.nama_lengkap : 'Deleted User')+" ")])])],1)]}},{key:"item.client",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('v-btn',{staticClass:"mr-2",attrs:{"depressed":""},on:{"click":function($event){$event.preventDefault();return _vm.viewClient(item)}}},[_c('p',{staticClass:"ma-0 small_txt"},[_vm._v(_vm._s(item.public ? item.public.nama_lengkap : 'Deleted User'))])])],1)]}},{key:"item.sesi",fn:function(ref){
var item = ref.item;
return [(item.sessions[0])?_c('div',{staticClass:"d-flex"},[_c('p',[_vm._v(_vm._s(item.sessions[0].schedule.booking_time))])]):_vm._e()]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"dark":"","color":item.status == 0
                    ? 'yellow'
                    : item.status == 1
                    ? 'green'
                    : 'red'}},[_vm._v(" "+_vm._s(item.status == 0 ? "Pending" : item.status == 1 ? "Aproved" : item.status == 2 ? "Rejected" : "EXPIRED")+" ")])]}},{key:"item.request",fn:function(ref){
                    var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('v-btn',{staticClass:"mr-2",attrs:{"depressed":""},on:{"click":function($event){$event.preventDefault();return _vm.viewReq(item)}}},[_vm._v(" View Evidence ")])],1)]}},{key:"item.actions",fn:function(ref){
                    var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('v-btn',{staticClass:"mr-2",staticStyle:{"min-width":"10px"},attrs:{"dark":"","depressed":"","color":"cyan"},on:{"click":function($event){$event.preventDefault();return _vm.updateItem(item)}}},[_c('v-icon',{attrs:{"dark":"","small":""}},[_vm._v(" mdi-cog ")])],1)],1)]}}],null,true)}),_c('div',{staticClass:"text-center pa-3"},[_c('v-pagination',{attrs:{"circle":"","length":_vm.list.last_page},on:{"input":_vm.fetchItems},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1),_c('v-row',[_c('v-col',{attrs:{"cols":"2"}},[_c('p',{staticClass:"mb-0"},[_vm._v("Tampilkan")]),_c('div',{staticClass:"d-flex align-center"},[_c('v-select',{attrs:{"items":_vm.listLimit,"dense":"","hide-details":"","outlined":""},on:{"change":_vm.fetchItems},model:{value:(_vm.limit),callback:function ($$v) {_vm.limit=$$v},expression:"limit"}}),_c('p',{staticClass:"ml-1 mb-0"},[_vm._v("Data")])],1)])],1),_c('div',{staticClass:"pa-2"},[_c('change-status-counseling',{attrs:{"dialogAlert":_vm.dialogAlert,"id":_vm.dataUser},on:{"refetch":_vm.fetchItems,"close":function($event){_vm.dialogAlert = false}}}),_c('profile-psy',{attrs:{"selected":_vm.dataUser,"d_profilePsy":_vm.d_profilePsy},on:{"close":function($event){_vm.d_profilePsy = false}}}),_c('profile-client',{attrs:{"selected":_vm.dataUser,"d_profileClient":_vm.d_profileClient},on:{"close":function($event){_vm.d_profileClient = false}}}),_c('view-evidence',{attrs:{"selected":_vm.dataUser,"d_evidence":_vm.d_evidence},on:{"close":function($event){_vm.d_evidence = false}}})],1)],1):_vm._e()])],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }