<template>
  <v-row justify="start">
    <v-dialog v-model="d_evidence" scrollable persistent max-width="500px">
      <v-card class="radius-card hide_overflow" flat>
        <v-toolbar class="pa-0" dense flat>
          <v-spacer></v-spacer>
          <v-btn fab x- small depressed @click="$emit('close')"
            ><v-icon small>mdi-close</v-icon></v-btn
          >
        </v-toolbar>
        <v-card-text class="mx-0 px-0">
          <v-container class="py-0">
            <div class="d-flex" v-if="selected">
              <img :src="selected" width="100%" alt="" />
            </div>
            <div
              class="d-flex justify-center align-center"
              v-if="!selected"
              style="height:200px"
            >
              <h3 class="text-center">Belum ada bukti pembayaran</h3>
            </div>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "viewEvidence",
  props: ["d_evidence", "selected"],
  computed: {
    ...mapState({
      env: state => state.API_URL,
      dummy: state => state.dummy
    })
  },
  data() {
    return {
      role: ""
    };
  },
  methods: {
    cancelStore() {
      this.$emit("close");
      this.$emit("refetch");
    }
  }
};
</script>

<style scoped>
.toolbar {
  width: 50%;
  background: #eae8e8;
  border-radius: 10px 0 0 10px !important;
}
.list_psy {
  width: 50%;
}
.another_filter {
  background: #eae8e8;
  padding: 5px;
  width: max-content;
  margin-bottom: -15px;
}
.circle_ttl {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #3b053c;
}
.litle_ {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #fff;
}
.pp {
  width: 100px;
}
.dp {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  object-fit: cover;
}
.active_time {
  background: #3b053c !important;
  color: #fff !important;
}
</style>
