<template>
  <v-row justify="start">
    <v-dialog v-model="d_profileClient" scrollable persistent max-width="500px">
      <v-card class="radius-card hide_overflow" flat>
        <v-toolbar class="pa-0" dense flat>
          <v-spacer></v-spacer>
          <v-btn fab x- small depressed @click="$emit('close')"
            ><v-icon small>mdi-close</v-icon></v-btn
          >
        </v-toolbar>
        <v-card-text class="mx-0 px-0">
          <v-container class="py-0">
            <div class="d-flex align-center mb-3" v-if="selected">
              <img
                :src="selected.photo ? selected.photo : dummy"
                alt=""
                class="dp mr-5"
              />
              <div>
                <h3>{{ selected.nama_lengkap }}</h3>
                <p class="small_txt ma-0">Email: {{ selected.email }}</p>
                <p class="small_txt ma-0">No hp: {{ selected.no_hp }}</p>
                <!-- <p class="small_txt">
                  {{ selected.data_jurusan.nama }} di
                  {{ selected.data_kota1.nama_kota }}
                </p> -->
              </div>
            </div>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "alertClient",
  props: ["d_profileClient", "selected"],
  computed: {
    ...mapState({
      env: state => state.API_URL,
      dummy: state => state.dummy
    })
  },
  data() {
    return {
      role: ""
    };
  },
  methods: {
    cancelStore() {
      this.$emit("close");
      this.$emit("refetch");
    }
  }
};
</script>

<style scoped>
.toolbar {
  width: 50%;
  background: #eae8e8;
  border-radius: 10px 0 0 10px !important;
}
.list_psy {
  width: 50%;
}
.another_filter {
  background: #eae8e8;
  padding: 5px;
  width: max-content;
  margin-bottom: -15px;
}
.circle_ttl {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #3b053c;
}
.litle_ {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #fff;
}
.pp {
  width: 100px;
}
.dp {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  object-fit: cover;
}
.active_time {
  background: #3b053c !important;
  color: #fff !important;
}
</style>
